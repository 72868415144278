@font-face {
    font-family: 'Roboto Regular';
    font-display: optional;
    src: local('Roboto Regular'), url(./assets/fonts/Roboto-Regular.woff2) format('truetype');
}

@font-face {
    font-family: 'Roboto Medium';
    font-display: optional;
    src: local('Roboto Medium'), url(./assets/fonts/Roboto-Medium.woff2) format('truetype');
}

@font-face {
    font-family: 'Roboto Bold';
    font-display: auto;
    src: local('Roboto Bold'), url(./assets/fonts/Roboto-Bold.woff2) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-display: auto;
    src: local('Montserrat'), url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter Black';
    font-display: optional;
    src: local('Inter Black'), url(./assets/fonts/Inter-Black.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter Bold';
    font-display: optional;
    src: local('Inter Bold'), url(./assets/fonts/Inter-Bold.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter ExtraBold';
    font-display: optional;
    src: local('Inter ExtraBold'), url(./assets/fonts/Inter-ExtraBold.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter ExtraLight';
    font-display: optional;
    src: local('Inter ExtraLight'), url(./assets/fonts/Inter-ExtraLight.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter Light';
    font-display: optional;
    src: local('Inter Light'), url(./assets/fonts/Inter-Light.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter Medium';
    font-display: optional;
    src: local('Inter Medium'), url(./assets/fonts/Inter-Medium.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter Regular';
    font-display: optional;
    src: local('Inter Regular'), url(./assets/fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter SemiBold';
    font-display: optional;
    src: local('Inter SemiBold'), url(./assets/fonts/Inter-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: 'Inter Thin';
    font-display: optional;
    src: local('Inter Thin'), url(./assets/fonts/Inter-Thin.ttf) format("truetype");
}
