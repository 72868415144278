.socialNetworkContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    order: 2;
    align-content: flex-start;
}

.socialNetworkContainer.mobile {
    order: unset;
    padding-top: 20px;
    padding-bottom: 20px;
    place-self: flex-start;
}

.socialNetworkContainer > .iconsBlock {
    padding: 10px 40px 10px 40px;
}

.socialNetworkContainer > .iconsBlock > a:not(:last-child) {
    padding-right: 10px;
}

.socialNetworkContainer > .iconsBlock > a > .icon {
    text-decoration: none;
    border: 0;
    width: 36px;
    height: auto;
}
