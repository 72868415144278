.alertContainer .acceptButton {
    background: rgb(62, 169, 246) !important;
    color: white !important;
    border-radius: 20px !important;
    font-family: 'Roboto Medium', sans-serif;
}

.alertContainer .content {
    flex: unset !important;
    margin: 15px 15px 0 15px !important;
    font-family: 'Roboto Regular', sans-serif;
    font-weight: 100;
    text-align: -webkit-center;
}

.alertContainer {
    justify-content: center !important;
    position: sticky !important;
}
